// User roles
export const UserRoles = Object.freeze({
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'administrator',
  MODERATOR: 'moderator',
  MEMBER: 'member'
})

// Helpline Options
export const HelplineOptions = Object.freeze({
  helpline: 'Helplines',
  'car-booking': 'Car Booking',
  'safe-home': 'Safe Home',
  'burial-service': 'Burial Service',
  'hearse-service': 'Hearse Van'
})

// Menu Items
export const menuItems = Object.freeze([
  { label: 'Home', link: '/', icon: 'mdi-home-outline' },
  { label: 'About GG', link: '/about', icon: 'mdi-account-outline' },
  { label: 'Discography', link: '/discography', icon: 'mdi-music-note' },
  { label: 'Media', link: '/media', icon: 'mdi-music-video' },
  { label: 'Albums', link: '/albums', icon: 'mdi-playlist-play' },
  { label: 'Literature', link: '/literature', icon: 'mdi-book' },
  { label: 'Gallery', link: '/gallery', icon: 'mdi-photo-album' },
  { label: 'Academy', link: '/academy', icon: 'mdi-school', mobileOnly: true }
  // { label: 'Contact Us', link: '/contacts', icon: 'mdi-cellphone-message', mobileOnly: true }
])

export const MEDAL_TYPES = Object.freeze({ G: 'Gold', S: 'Silver', B: 'Bronze' })

export const GENDERS = Object.freeze(['Male', 'Female', 'Mixed', 'General'])

export const AGE_CATEGORIES = Object.freeze(['Under 14', 'Under 17', 'General'])

export const TEAM_CATEGORIES = Object.freeze(['Singles', 'Doubles'])

export const EVENTS = Object.freeze([
  { name: '100 Meter Run', age_categories: true, gender_categories: true },
  { name: '200 Meter Run', age_categories: true, gender_categories: true },
  { name: '800 Meter Run', age_categories: true, gender_categories: true },
  { name: '1600 Meter Run', age_categories: true, gender_categories: true },
  { name: 'Long Jump', age_categories: true, gender_categories: true },
  { name: 'Shot Put', age_categories: true, gender_categories: true },
  { name: 'Discus Throw', age_categories: true, gender_categories: true },
  { name: 'Hurdle Race', age_categories: true, gender_categories: true },
  { name: 'Badminton', team_categories: true, gender_categories: true },
  { name: 'Table Tennis', team_categories: true, gender_categories: true },
  { name: 'Chess', gender_categories: true },
  { name: 'Kabaddi', gender_categories: true },
  { name: 'Kho Kho', gender_categories: true },
  { name: 'Volleyball', gender_categories: true },
  { name: 'Football', gender_categories: true },
  { name: 'Cricket', gender_categories: true }
])

export const WARD_LIST = Object.freeze([
  ...[...Array(23).keys()].map(i => `AKM Ward ${i + 1}`),
  ...[...Array(24).keys()].map(i => `Habra Ward ${i + 1}`),
  'Banspole GP',
  'Bargum-I',
  'Bargum-II',
  'Beraberi GP',
  'Bira Rajibpur',
  'Bhurkunda GP',
  'Dighara Malikberia GP',
  'Guma-I',
  'Guma-II',
  'Kumra GP',
  'Machhlandapur-I',
  'Machhlandapur-II',
  'Prithiba GP',
  'Rautara GP',
  'Srikrishnapur GP'
])
